import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'

import StructuredJsonBlockFields from './StructuredJsonBlockFields'

export default function StructuredJsonTabs(props) {
  const {
    api,
    name,
    blocks,
    selected,
    schema,
    max,
    jsonPath,
    onAddItem,
    onDeleteItem,
    onSelect,
    onUpdate,
  } = props
  const [expanded, setExpanded] = useState(false)
  const canAddItem = !max || max > blocks.length
  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      elevation={0}
      style={{ border: '1px solid rgb(204 204 204)' }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ paddingRight: 12 }}>{name}</Typography>
          {expanded && canAddItem && (
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                onAddItem()
              }}
            >
              <span style={{ fontSize: 10 }}>ADD CHILD BLOCK</span>
            </Button>
          )}
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'inherit' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            overflow: 'scroll',
            marginBottom: 12,
            position: 'relative',
            zIndex: 1,
          }}
        >
          {blocks.map((_, index) => {
            const itemId = name + index
            const isSelected = selected === itemId
            return (
              <Button
                variant={isSelected ? 'outlined' : 'text'}
                style={{
                  marginRight: 8,
                  borderColor: 'rgb(204 204 204)',
                  borderBottomColor: isSelected ? 'white' : 'rgb(204 204 204)',
                  borderRadius: '4px 4px 0 0',
                }}
                onClick={() => onSelect(itemId)}
              >
                {index + 1}.{' '}
              </Button>
            )
          })}
        </div>
        <hr
          style={{
            background: 'rgb(204, 204, 204)',
            border: 0,
            height: 1,
            margin: 0,
            position: 'relative',
            top: -13,
            zIndex: 0,
          }}
        />
        {blocks.map((fields, index) => {
          return selected === name + index ? (
            <StructuredJsonBlockFields
              index={index}
              fields={fields}
              schema={schema}
              jsonPath={jsonPath}
              api={api}
              onDeleteItem={onDeleteItem}
              onUpdate={onUpdate}
            />
          ) : null
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
